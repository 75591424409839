import Head from "next/head";
import { useEffect, useState } from "react";
import FooterPresenter from "../components/Footer/Footer.presenter";
import Homepage from "../components/Homepage/Homepage.presenter";
import Menu from "../components/Menu/Menu.presenter";
import "../i18n/index";
export default function Home() {
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);
  return (
    <>
      <Head>
        <title>Wasabi - QD.Tek Distribution Partner</title>
        <meta name="description" content="wasabi" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
        {!isSSR && (
          <>
            <Menu />
            <Homepage />
            <FooterPresenter />
          </>
        )}
      </main>
    </>
  );
}
